import React from "react";
import { graphql, PageProps } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";

import { TwoColumnLayout, Column } from "src/components/layout/TwoColumnLayout";
import { PageLayout } from "src/components/layout/PageLayout";
import { Text } from "src/components/Text";
import { breakpoint } from "src/utils/media-query";
import { getImageMetadata } from "src/utils/image";
import { BodyText } from "src/components/BodyText";

interface StoryPageProps extends PageProps {
  data: GatsbyTypes.StoryPageQuery;
}

const StoryPage: React.FC<StoryPageProps> = ({ data }) => {
  const [ storyImage, storyImageAlt ] = getImageMetadata(data.strapiStory?.story_image);
  const storyTitle = data.strapiStory?.story_title;
  const storyTagline = data.strapiStory?.tagline;
  const storyText = data.strapiStory?.story_text;
  const storyParagraphs = storyText?.split(/\n+/g);
  // const storyLink = data.strapiStory?.Link;

  const storyBody = storyParagraphs?.map((paragraph, i) => (
    paragraph.startsWith("## ") ? (
      <SubheadingText key={`${i + 1}`} as="h2" color="red">
        {paragraph.replace(/#+ /, "")}
      </SubheadingText>
    ) : (
      <BodyText
        key={`${i + 1}`}
        as="p"
        firstLineCaps={i === 0}
        indented
        text={paragraph}
      />
    )
  ));

  return (
    <PageLayout title="Dennis Severs" width="wide">
      <TitleWrapper>
        <Text as="h1" uppercase>
          Dennis Severs
        </Text>
      </TitleWrapper>
      <StyledTwoColumnLayout>
        {storyImage && (
          <Column>
            <TextContent>
              <Text color="red">
                {storyTagline}
              </Text>
            </TextContent>
            <GatsbyImage image={storyImage} alt={storyImageAlt} />
            <CaptionText as="label" firstLineCaps>
              Dennis Severs, taken by Barbara and René Stoeltie
            </CaptionText>
          </Column>
        )}
        {storyTitle && storyText && (
          <Column>
            <TextContent>
              <BodyWrapper>
                {storyBody}
                {/*
                {storyLink && (
                  <StyledLink href={storyLink.link_url}>
                    {storyLink.link_text}
                  </StyledLink>
                )}
                 */}
              </BodyWrapper>
            </TextContent>
          </Column>
        )}
      </StyledTwoColumnLayout>
    </PageLayout>
  );
};

export default StoryPage;

export const query = graphql`
  query StoryPage {
    strapiStory {
      story_title
      tagline
      story_image {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
      story_text
      Link {
        link_text
        link_url
      }
    }
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3rem;
`;

const StyledTwoColumnLayout = styled(TwoColumnLayout)`
  ${breakpoint("tabletLL")} {
    padding-bottom: 2rem;
  }
`;

const CaptionText = styled(Text)`
  align-self: center;
  margin: 1rem;
  text-align: center;
  line-height: 1.4rem;
  letter-spacing: 2px;
  font-variant: all-small-caps;
`;

const TextContent = styled.div`
  margin: 1rem;
  margin-top: 3rem;
  margin-bottom: 2rem;

  ${breakpoint("tabletLL")} {
    margin-left: 0;
    margin-right: 0;
  }
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubheadingText = styled(Text)`
  line-height: 1.4rem;
  letter-spacing: 2px;
  font-variant: all-small-caps;
  margin-top: 2rem;
  margin-bottom: 1rem;

  & + p:first-line {
    text-indent: 0;
  }
`;